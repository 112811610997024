// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../Assets/img/medlogo.png";

import doctor from "../Assets/img/doctor.png";
import pharmacy from "../Assets/img/phar.png";
import labImg from "../Assets/img/lab.png";
import health from "../Assets/img/health.png";
import "../StyleSheets/homestyle.css";
import PopUp from "./PopUp";
import HeroPanel from "./Common/HeroPanel";
import Header from "./Common/Header";
import Footer from "./Common/Footer";

// import {
//   faFacebook,
//   faTwitter,
//   faInstagram,
//   faYoutube,
// } from "@fortawesome/free-brands-svg-icons";
interface Home {
  subscriptionStatus: boolean;
  tabs: string[];
  tabIndex: number;
  subPopUp: boolean;
  message: string;
  msisdn: string;
  onUnSubscribePressed: () => void;
  onClickTabIndex: (index: number) => void;
  closePopUp: () => void;
  onSubscribePressed: () => void;
  networkPackage: string | null;
}
const Home = (props: Home) => {
  // let msisdn = localStorage.getItem("msisdn");
  return (
    <div>
      <Header
        onUnSubscribePressed={props.onUnSubscribePressed}
        onSubscribePressed={props.onSubscribePressed}
        networkPackage={props.networkPackage}
      />

      {props.subPopUp && (
        <div className="main-container-alert-modal">
          <PopUp
            subPopUp={props.subPopUp}
            message={props.message}
            closePopUp={props.closePopUp}
          />
        </div>
      )}

      <HeroPanel />

      <section className="offerSec">
        <div className="offerSec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="service">
                  <div className="service-text">
                    medIQ Service for Zong users
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="doctor-section">
        <div className="doctor-content doc">
          <div className="doc-text">
            <div className="doc-heading">Doctor Teleconsultations</div>
            <div className="doc-sub">
              Why go to a hospital when you can get connected with a doctor on
              the phone?
            </div>
          </div>

          <div className="doc-image">
            <img src={doctor} />
          </div>

          <div className="doc-con-text">
            <div className="text">
              <p>
                Avail FREE video consultation on medIQ mobile app with GP
                Doctors anywhere anytime.
              </p>
              <br />
              <p>
                Doctors from over 32 Specialities at discounted rates are
                available for consultation at the point of need.
              </p>
            </div>

            <div className="con-button">
              <a
                href="https://onelink.to/hp8jsk"
                className="contactBtn"
                target="_blank"
              >
                {" "}
                Download App
              </a>
            </div>
          </div>
        </div>
        <div className="doctor-content ">
          <div className="doc-text">
            <div className="doc-heading">Pharmacy</div>
            <div className="doc-sub">
              Get Your Medicines in Time at your Doorstep
            </div>
          </div>

          <div className="doc-image">
            <img src={pharmacy} />
          </div>

          <div className="doc-con-text">
            <div className="text">
              <p>
                Order through medIQ App and get medicines delivered at your
                home.
              </p>
              <br />
              <p>
                Patients can choose from a wide range of products for home
                medicine delivery.{" "}
              </p>
              <p>Genuine medicines are available at discounted rates.</p>
            </div>

            <div className="con-button">
              <a
                href="https://onelink.to/hp8jsk"
                className="contactBtn"
                target="_blank"
              >
                {" "}
                Download App
              </a>
            </div>
          </div>
        </div>
        <div className="doctor-content doc">
          <div className="doc-text">
            <div className="doc-heading">Labs</div>
            <div className="doc-sub">
              Why go to the lab when you can get the lab to come to your home?
            </div>
          </div>

          <div className="doc-image">
            <img src={labImg} />
          </div>

          <div className="doc-con-text">
            <div className="text">
              <p>
                Place an order on the medIQ app and get your lab sample
                collected at home.
              </p>
              <br />
              <p>
                Get laboratory tests done easily with our Home Sample
                Collection.
              </p>
              <p>All leading laboratories of Pakistan are available .</p>
            </div>

            <div className="con-button">
              <a
                href="https://onelink.to/hp8jsk"
                className="contactBtn"
                target="_blank"
              >
                {" "}
                Download App
              </a>
            </div>
          </div>
        </div>

        <div className="doctor-content ">
          <div className="doc-text">
            <div className="doc-heading">Health & Wellness</div>
            <div className="doc-sub">Stay active, health and happy</div>
          </div>

          <div className="doc-image">
            <img src={health} />
          </div>

          <div className="doc-con-text">
            <div className="text">
              <p>
                Get daily health tips, diet plans, nutritional guidelines, home
                remedies, and much more, designed to support overall health and
                well-being.
              </p>
              <br />
              <p>
                Keep up to date with the latest trends and developments with our
                informative health blogs and engaging videos.
              </p>
            </div>

            <div className="con-button">
              <a
                href="https://onelink.to/hp8jsk"
                className="contactBtn"
                target="_blank"
              >
                {" "}
                Download App
              </a>
            </div>
          </div>
        </div>
      </div>

      {/*footer */}
      <Footer />
    </div>
  );
};

export default Home;
