import React, { useEffect, useState } from "react";
import { IValidateSignature, IViladateHE } from "../@types/apiresponse";
import ApiNames from "../Constants/ApiNames";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import LandingMzaPage from "../Components/LandingMzaPage";
import { useNavigate } from "react-router-dom";

type Props = {
  token: any;
};

const LandingMzaScreen = (props: Props) => {
  // State to manage the visibility of a main loading indicator
  const [mainLoader, setMainLoader] = useState<boolean>(true);
  // State to manage the visibility of a secondary loading indicator, perhaps for a specific operation like subscription
  const [isLoader, setIsLoader] = useState<boolean>(false);
  // State to control the visibility of a modal. This is used to show or hide a modal dialog in the UI
  const [showModal, setShowModal] = useState<boolean>(false);
  // State to track whether the user has subscribed to a service
  const [userSubscribed, setUserSubscribed] = useState<boolean>(false);
  // State to store and display an error message if an operation fails or encounters an issue
  const [errorMsg, setErrorMsg] = useState<string>("");

  const urlParams = new URL(window.location.href);

  // Extract the value of the "signature" parameter from the URL's query string (which is a key for a specific user)
  const signature = encodeURIComponent(
    urlParams.searchParams.get("signature") || ""
  );

  // Extract the value of the "data" parameter (which in this case is phone number) from the URL's query string
  const msisdn = urlParams.searchParams.get("data");

  const navigate = useNavigate();

  useEffect(() => {
    if (props.token) {
      // If both signature and msisdn are present in the URL, validate the signature
      if (signature && msisdn) {
        validateSignature(signature, msisdn);
      } else {
        // If either signature or msisdn is missing, stop the loader and navigate to the landing page
        setMainLoader(false);
        navigate("/landing");
      }
    }
  }, [props.token]);

  const validateSignature = async (signature: string, msisdn: string) => {
    //This function validates the signature
    try {
      const data = { signature, msisdn };

      let response: IViladateHE = await fetchApiPost(
        ApiNames.validateSignature,
        props.token,
        data
      );

      if (response.status === 0 && response.result.toLowerCase() === "valid") {
        setMainLoader(false);
        checkUserStatus();
      } else {
        navigate("/landing");
      }
    } catch (error) {
      navigate("/landing");
      console.error("Validation error:", error);
    }
  };

  //This function is to check if the user exists or not
  const checkUserStatus = async () => {
    try {
      let response = await fetchApiGet(
        props.token,
        `${ApiNames.checkUser}/${msisdn}`
      );

      if (response.status === 0 && response.result.toLowerCase() === "active") {
        // If the user exists, update the state to mark them as subscribed
        setUserSubscribed(true);
      }
    } catch (error) {
      console.error("Error checking user status:", error);
      setErrorMsg("Something went wrong");
    }
  };

  //This function validates and subscribe the user. _packageName used to identify the subscription package the user is attempting to subscribe.
  const validateSubscribe = async (_packageName: string) => {
    try {
      setIsLoader(true);
      setErrorMsg("");
      const data = {
        signature: signature,
        msisdn: msisdn,
        package_name: _packageName,
      };

      let response: IValidateSignature = await fetchApiPost(
        ApiNames.validateSubscribe,
        props.token,
        data
      );
      if (response.status === 0) {
        //If the response is successful then this state converts subscription status to download
        setUserSubscribed(true);
      } else {
        setErrorMsg("Subscription failed. Please try again.");
      }
    } catch (error) {
      console.error("Subscription error:", error);
      setErrorMsg("Something went wrong");
    } finally {
      setIsLoader(false);
    }
  };

  //This Function controls whether the modal is shown (true) or hidden (false).
  const onModalPress = () => setShowModal(!showModal);

  return (
    <LandingMzaPage
      validateSubscribe={validateSubscribe}
      mainLoader={mainLoader}
      isLoader={isLoader}
      onModalPress={onModalPress}
      showModal={showModal}
      userSubscribed={userSubscribed}
      errorMsg={errorMsg}
    />
  );
};

export default LandingMzaScreen;
