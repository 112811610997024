import React, { useEffect, useState } from "react";
import ApiNames from "../Constants/ApiNames";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import { fetchApiPost, fetchApiGet } from "../Utils/FetchApi";
import HomeScreen from "../Screens/HomeScreen";
import LandingScreen from "../Screens/LandingScreen";
import LandingOTPScreen from "../Screens/LandingOTPScreen";
import TagManager from "react-gtm-module";
import { useSearchParams } from "react-router-dom";
import { decode } from "base-64";
import Swal from "sweetalert2";
import AwarenessScreen from "../Screens/AwarenessScreen";
import BeautyScreen from "../Screens/BeautyScreen";
import SubscriptionPlanScreen from "../Screens/SubscriptionPlanScreen";

import moment from "moment";
import LandingMzaScreen from "../Screens/LandingMzaScreen";

export default function App() {
  const [token, setToken] = useState(null);
  const [msisdn, setMsisdn] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    generateToken();
    const tagManagerArgs = {
      gtmId: "GTM-MQ7FZ26M",
    };
    TagManager.initialize(tagManagerArgs);
    let _msisdn = localStorage.getItem("msisdn");
    if (_msisdn) {
      setMsisdn(_msisdn);
    } else {
      checkUser();
    }
  }, []);
  useEffect(() => {
    if (token) {
      let _packageChangeTime = localStorage.getItem("packageChangeTime");
      if (_packageChangeTime) {
        let perv = moment(_packageChangeTime);
        let now = moment();
        let time = now.diff(perv, "minutes");
        if (time > 30) {
          fetchCheckUser();
        }
      } else {
        fetchCheckUser();
      }
    }
  }, [token]);

  const generateToken = async () => {
    try {
      let data = {
        username: "QueenIsArrow",
        password: "QueenIsArrow",
      };
      let response = await fetchApiPost(ApiNames.authentication, null, data);
      if (response.status === 0) {
        setToken(response.token);
      }
    } catch (error) {
      console.log("authenticateToken error :", error);
    }
  };

  const checkUser = async () => {
    try {
      console.log("first");
      let url = window.location.href;
      if (
        window.location.pathname === "/home" &&
        url.includes("?") &&
        url.includes("user") &&
        url.includes("package") &&
        url.includes("date")
      ) {
        let _msisdn: string | null = searchParams.get("user");
        let _package: any = searchParams.get("package");
        let _date: any = searchParams.get("date");

        console.log(_package);
        if (_msisdn) _msisdn = decode(_msisdn);
        if (_package) _package = decode(_package);
        if (_date) _date = decode(_date);
        if (_msisdn && _msisdn.length === 10) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "You are subscribed successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setMsisdn(_msisdn);
          localStorage.setItem("msisdn", _msisdn);
          localStorage.setItem("networkPackage", _package);
          localStorage.setItem("packageChangeTime", _date);
          console.log("I m in checkUser");
        }
      }
    } catch (error) {
      console.log("checkUser error", error);
    }
  };

  const fetchCheckUser = async () => {
    try {
      if (msisdn) {
        let response = await fetchApiGet(
          token,
          `${ApiNames.checkUser}/${msisdn}`
        );
        if (response.result.toLowerCase().includes("active")) {
          localStorage.setItem("subpackage", response.currentPackage);
          console.log(response.currentPackage);
        } else if (
          response.status === -1 &&
          response.result.toLowerCase().includes("user not found")
        ) {
          localStorage.removeItem("msisdn");
          setMsisdn("");
          localStorage.removeItem("packageChangeTime");
        }
      }
    } catch (error) {
      console.log("fetchCheck user error", error);
    }
  };

  const removeMsisdn = () => {
    localStorage.removeItem("msisdn");
    setMsisdn("");
  };

  return (
    <Routes>
      <Route path="/*" element={<Navigate to={"/home"} replace={true} />} />
      <Route
        path="/home"
        element={
          <HomeScreen
            token={token}
            msisdn={msisdn}
            removeMsisdn={removeMsisdn}
          />
        }
      />
      <Route
        path="/landing"
        element={
          msisdn ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <LandingScreen token={token} />
          )
        }
      />
      <Route path="/landingmza" element={<LandingMzaScreen token={token} />} />
      <Route
        path="/phone"
        element={
          msisdn ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <LandingScreen token={token} />
          )
        }
      />
      <Route
        path="/awareness"
        element={
          msisdn ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <AwarenessScreen token={token} />
          )
        }
      />
      <Route
        path="/beauty"
        element={
          msisdn ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <BeautyScreen token={token} />
          )
        }
      />
      <Route
        path="/pin"
        element={
          localStorage.getItem("msisdn") ? (
            <Navigate to={"/home"} replace={true} />
          ) : (
            <LandingOTPScreen token={token} />
          )
        }
      />

      <Route
        path="/subscription"
        element={
          localStorage.getItem("msisdn") ? (
            <SubscriptionPlanScreen token={token} msisdn={msisdn} />
          ) : (
            <Navigate to={"/home"} replace={true} />
          )
        }
      />
    </Routes>
  );
}
