import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="footer-link">
                <div className="foo-heading">Social</div>

                <div className="social-link">
                  <div className="icon">
                    <a
                      href="https://www.youtube.com/c/mediqsmarthealthcare"
                      className="f-link"
                    >
                      <span> Youtube</span> <FontAwesomeIcon icon={faYoutube} />{" "}
                    </a>
                  </div>

                  <div className="icon">
                    <a
                      href="https://www.instagram.com/mediq_smart_healthcare/"
                      className="f-link"
                    >
                      {" "}
                      <span>Instagram</span>{" "}
                      <FontAwesomeIcon icon={faInstagram} />{" "}
                    </a>
                  </div>

                  <div className="icon">
                    <a href="https://twitter.com/ShcMediq" className="f-link">
                      {" "}
                      <span> Twitter</span> <FontAwesomeIcon icon={faTwitter} />{" "}
                    </a>
                  </div>

                  <div className="icon">
                    <a
                      href="https://web.facebook.com/mediq.smarthealthcare"
                      className="f-link"
                    >
                      {" "}
                      <span> Facebook</span>{" "}
                      <FontAwesomeIcon icon={faFacebook} />{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="footer-link">
                <div className="foo-heading">Contact Us</div>

                <div className="contact-text">
                  <p>UAN: 051 111 377 377 </p>
                  <p>Phone: 051 877 3377 </p>
                  <p>
                    Web:{" "}
                    <a
                      href="https://mediq.com.pk/"
                      target="_blank"
                      className="mediqhref"
                    >
                      www.mediq.com.pk
                    </a>
                  </p>
                </div>
                <div className="footer-text">© 2024. All rights reserved.</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
