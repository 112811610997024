import React from "react";
import bannerImage from "../../Assets/img/bannerImg.png";

const HeroPanel = () => {
  return (
    <>
      <section className="bannerSec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="bannerImg">
                {" "}
                <img src={bannerImage} alt="banner" />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="bannerHead">
                <h1>MedIQ </h1>
              </div>
              <div className="sub-heading">
                <h1>Smart Healthcare</h1>
              </div>
              <div className="bannerText">
                <p>
                  is redefining the healthcare industry and delivering
                  unparalleled service to its customers. Through medIQ app, you
                  can benefit from the one-stop-shop solution for all your
                  healthcare needs. The platform is committed to making quality
                  healthcare services affordable, accessible, convenient,
                  efficient, and cost-effective{" "}
                </p>
              </div>
              <div className="bannerBtn">
                <a
                  href="https://onelink.to/hp8jsk"
                  target="_blank"
                  className="contactBtn"
                >
                  Download App
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.24408 5.74408C8.56951 5.41864 9.09715 5.41864 9.42259 5.74408L13.5893 9.91074C13.9147 10.2362 13.9147 10.7638 13.5893 11.0893L9.42259 15.2559C9.09715 15.5814 8.56951 15.5814 8.24408 15.2559C7.91864 14.9305 7.91864 14.4028 8.24408 14.0774L11.8215 10.5L8.24408 6.92259C7.91864 6.59715 7.91864 6.06951 8.24408 5.74408Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroPanel;
